import { render, staticRenderFns } from "./CancellationSidebar.vue?vue&type=template&id=5c03f91e&scoped=true"
import script from "./CancellationSidebar.vue?vue&type=script&lang=js"
export * from "./CancellationSidebar.vue?vue&type=script&lang=js"
import style0 from "./CancellationSidebar.vue?vue&type=style&index=0&id=5c03f91e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c03f91e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCheckbox,VIcon})
