<template>
  <div ref="sidebar" class="cancellation-sidebar">
    <div v-if="!loading" class="cancellation-sidebar--content">
      <p style="font-size: 16px; margin-top: 5px; margin-bottom: 10px">
        <b>Classification:</b>
        {{ classificationLabel }}
      </p>
      <div v-if="clientInitiatedCancellation">
        <div style="display: flex; align-items: center; padding-bottom: 5px">
          <p style="font-size: 16px; margin-bottom: 0; margin-right: 10px">
            <b>Cancellation Form:</b>
            {{ cancellationEmailSent ? 'Sent' : 'Not Sent' }}
          </p>
          <CRTag
            v-if="cancellationEmailSent"
            :label="cancellationConfirmed ? 'Complete' : 'Incomplete'"
            :color="cancellationConfirmed ? 'green' : 'red'"
          />
        </div>
        <div v-if="cancellationConfirmed">
          <p style="margin-top: 5px; margin-bottom: 0px">
            <b>Requester Name:</b>
            {{ cancellationConfirmation.requesterName }}
          </p>
          <p style="margin-top: 5px; margin-bottom: 0px">
            <b>Phone:</b>
            {{ cancellationConfirmation.requesterPhone }}
          </p>
          <p style="margin-top: 5px; margin-bottom: 0px">
            <b>Email:</b>
            {{ cancellationConfirmation.requesterEmail }}
          </p>
          <p style="margin-top: 5px; margin-bottom: 0px">
            <b>Payment Method Used:</b>
            {{ cancellationConfirmation.paymentMethod }}
          </p>
          <p
            v-if="cancellationConfirmation.cardType"
            style="margin-top: 5px; margin-bottom: 0px"
          >
            <b>Credit Card:</b>
            {{ cancellationConfirmation.cardType }}
          </p>
          <p style="margin-top: 5px; margin-bottom: 0px">
            <b>User Booked and Agreed to Terms:</b>
            {{
              cancellationConfirmation.didRequesterAgreeToTerms ? 'Yes' : 'No'
            }}
          </p>
          <p
            v-if="!cancellationConfirmation.didRequesterAgreeToTerms"
            style="margin-top: 5px; margin-bottom: 0px"
          >
            <b>User is Authorized to Cancel:</b>
            {{
              cancellationConfirmation.isUserAuthorizedToCancel ? 'Yes' : 'No'
            }}
          </p>
        </div>
        <v-checkbox
          v-else
          :id="`allow-cancel-without-confirmation-form-checkbox`"
          v-model="allowCancelWithoutForm"
          style="margin-top: 10px"
          label="Cancel reservation without completed form"
        />
        <hr class="section-divider" />
      </div>
      <div style="display: flex; align-items: center; padding-bottom: 20px">
        <CRIcon
          view-box="0 0 24 24"
          :width="20"
          :height="20"
          color="red"
          style="flex-shrink: 0"
        >
          alert_outline
        </CRIcon>
        <p style="color: #e10055; margin: 0; padding-left: 10px">
          {{ warningMessage }}
        </p>
      </div>
      <CRTextArea
        v-if="clientInitiatedCancellation"
        id="email-comments-text-area"
        ref="email-comments"
        v-model="cancellationComment"
        placeholder="Additional comments about the cancellation details here."
        :disabled="!cancellationConfirmed"
        :rows="4"
        auto-grow
      />
    </div>
    <div v-if="isChargePenaltyOnCancelEnabled">
      <v-checkbox
        :id="`charge-cancel-fee-to-credit-card-checkbox`"
        v-model="showPaymentFeeSection"
        class="margin-x-10"
        :disabled="disableChargePenalty"
        :label="`Charge cancellation fee to credit card`"
      ></v-checkbox>
      <div
        v-if="
          checkedOutWithCreditCardAndCardsOnFile && cancellationChargeAmount > 0
        "
        class="flex-row align-center w-500 h-72 background-blue-pale auth-payment-notice"
      >
        <v-icon size="20" color="primary" class="margin-l-10 margin-r-3">
          mdi-information-outline
        </v-icon>
        <p class="margin-r-10 margin-b-0">{{ authPaymentCopy }}</p>
      </div>
      <CollectPaymentNew
        v-if="showPaymentFeeSection"
        :id="`client-cancellation-penalty`"
        ref="collect-payment-new"
        v-bind="$attrs"
        :balance="cancellationChargeAmount"
        :default-selected-payment-method="PaymentMethodTypeKey.CreditCard"
        :default-selected-card-index="defaultSelectedCardIndex"
        :row="{ reservationId: reservationId }"
        is-customer-cancel
        :disable-submit="disableSubmit"
        @confirm-cancellation="(payload) => submitWithFee(payload)"
      />
    </div>
    <div>
      <CRButton
        id="cancellation-save-btn"
        :loading="loading"
        :disabled="disableSubmit"
        class="cancellation-sidebar action-btn"
        color="primary"
        text-color="white"
        @click="submit"
      >
        {{ submitButtonText }}
      </CRButton>
    </div>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import { mapActions, mapGetters } from 'vuex'
import ticket from '@/services/ticket'
import { getCancellationConfirmation } from '@/services/reservations'
import CollectPaymentNew from './CollectPaymentNew.vue'
import { diffInDaysFromNow } from '@/utils/time'
import { filter } from '@/utils/filter'
import { friendlyDate } from '@/utils/time'
import { DateTime } from 'luxon'
import { currencyFilter } from '@/utils/string'
import authPayment from '@/services/authPayment'
import { PaymentMethodTypeKey } from '@/utils/enum'

const CLIENT_INITIATED_CANCELLATION_CLASSIFICATION_ID = 17

export default {
  components: {
    CollectPaymentNew,
  },
  props: {
    ticketId: {
      type: Number,
      default: null,
    },
    reservationId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      reservation: null,
      cancellationComment: '',
      cancellationConfirmation: null,
      allowCancelWithoutForm: false,
      loading: false,
      paymentProfiles: [],
      showPaymentFeeSection: false,
      preAuthorizedAmount: null,
      authPaymentOnCheckout: null,
      authorizedOn: null,
      meta: null,
      mask: null,
      label: null,
      takeCancellationPenalty: false,
      paymentProfileId: null,
      cancellationPenaltyAmount: null,
      sendPaymentEmail: false,
      PaymentMethodTypeKey: PaymentMethodTypeKey,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isChargePenaltyOnCancelEnabled:
        'featureToggles/isChargePenaltyOnCancelEnabled',
    }),
    defaultSelectedCardIndex() {
      const index = this.paymentProfiles.findIndex((p) => p.mask === this.mask)
      return index !== -1 ? index : null
    },
    cancellationFee() {
      if (diffInDaysFromNow(this.reservation?.startDate) > 29) {
        return 0
      }
      if (diffInDaysFromNow(this.reservation?.startDate) < 3) {
        return this.reservation?.amount
      }
      return (this.reservation?.amount * 0.1).toFixed(2)
    },
    cancellationChargeAmount() {
      return Math.max(
        this.cancellationFee - this.reservation?.totalCredits,
        0
      ).toFixed(2)
    },
    checkedOutWithCreditCardAndCardsOnFile() {
      return this.meta != null && this.paymentProfiles.length > 0
    },
    authPaymentCopy() {
      if (this.preAuthorizedAmount && this.authorizedOn && this.meta) {
        return `${
          this.label?.charAt(0)?.toUpperCase() + this.label?.slice(1)
        } *${this.mask} was pre-authorized for ${currencyFilter(
          this.preAuthorizedAmount
        )} at checkout on ${friendlyDate(this.authorizedOn)}`
      } else if (this.meta) {
        return `${
          this.label?.charAt(0)?.toUpperCase() + this.label?.slice(1)
        } *${this.mask} used at checkout. No funds are held.`
      }
      return ''
    },
    clientInitiatedCancellation() {
      return (
        this.reservation?.cancellationParentClassificationId ==
        CLIENT_INITIATED_CANCELLATION_CLASSIFICATION_ID
      )
    },
    cancellationEmailSent() {
      return this.reservation?.customerCancellationEmailSent
    },
    classificationLabel() {
      return (
        this.reservation?.cancellationClassificationLabel || 'None Selected'
      )
    },
    cancellationConfirmed() {
      return this.cancellationConfirmation != null
    },
    warningMessage() {
      if (this.clientInitiatedCancellation) {
        return 'This comment will be visible to the customer in the cancellation confirmation email. Please make sure to review before sending.'
      }
      return `Cancel reservation for ID ${this.reservation?.managedId}?`
    },
    submitButtonText() {
      const submitButtonText = 'Cancel Reservation'
      if (this.allowCancelWithoutForm) {
        return submitButtonText
      }
      return this.clientInitiatedCancellation
        ? `${submitButtonText} and Send Email`
        : submitButtonText
    },
    disableChargePenalty() {
      return !this.checkedOutWithCreditCardAndCardsOnFile || this.cancellationChargeAmount <= 0
    },
    disableSubmit() {
      if (this.allowCancelWithoutForm) {
        return false
      }
      if (this.clientInitiatedCancellation) {
        return !this.cancellationConfirmed || this.cancellationComment == ''
      }
      return false
    },
  },
  watch: {
    allowCancelWithoutForm() {
      if (this.allowCancelWithoutForm) {
        this.cancellationComment = ''
      }
    },
    showPaymentFeeSection() {
      if (this.showPaymentFeeSection) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.scrollToBottom()
          }, 1300)
        })
      }
    },
  },
  async mounted() {
    await this.getReservation()
    await this.customerPaymentProfiles()
    await this.cancellationPaymentInfo()

    if (!this.disableChargePenalty) {
      this.showPaymentFeeSection = true
    }

    const cancellationConfirmationResult = await getCancellationConfirmation(this.reservationId)
    this.cancellationConfirmation = cancellationConfirmationResult?.data?.cancellationConfirmation
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      getCustomerPaymentProfiles: 'payments/getCustomerPaymentProfiles',
    }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async getReservation() {
      this.loading = true
      const response = await this.$store.dispatch(
        'reservations/reservationById',
        this.reservationId
      )
      this.reservation = response?.data
      this.loading = false
    },
    async customerPaymentProfiles() {
      const filters = filter()

      if (!this.reservationId) {
        return
      }

      const customerId = this.reservation.customerId
      if (!customerId) {
        this.paymentProfiles = []
        return
      }
      const parentFilter = filters.createParent('and')
      const customerIdFilter = {
        column: {
          _t_id: 'customerIdFilter_filter',
          prop: 'customerId',
          filterType: 'eq',
        },
        value: customerId,
      }
      const showToCustomerFilter = {
        column: {
          _t_id: 'showToCustomerFilter_filter',
          prop: 'showToCustomer',
          filterType: 'eq',
        },
        value: true,
      }
      filters.add(parentFilter, customerIdFilter)
      filters.add(parentFilter, showToCustomerFilter)

      const params = {
        pageSize: -1,
        filters: filters.asQueryParams(),
      }
      const customerPaymentProfilesResponse = await this.getCustomerPaymentProfiles(
        params
      )
      const paymentProfilesResults =
        customerPaymentProfilesResponse?.data?.resultList || []
      this.paymentProfiles = paymentProfilesResults.map((p) => {
        const {
          customerPaymentProfileId,
          mask,
          label,
          address,
          accountHolderName,
          expiration,
        } = p
        return {
          customerPaymentProfileId,
          mask,
          typeLabel: label,
          address,
          accountHolderName,
          expiration,
        }
      })
    },
    async cancellationPaymentInfo() {
      const authPaymentResponse = await authPayment.getCancellationPaymentInfo(
        this.reservation?.quoteId
      )
      const authPaymentResult = authPaymentResponse?.data?.authPayments

      //get earliest authPayment as checkout transaction
      if (authPaymentResult && authPaymentResult.length > 0) {
        this.authPaymentOnCheckout = authPaymentResult.reduce(
          (earliestPayment, currentPayment) =>
            currentPayment.createdOn < earliestPayment.createdOn
              ? currentPayment
              : earliestPayment
        )

        this.meta = JSON.parse(this.authPaymentOnCheckout?.meta)
        const { mask, type_label } = this.meta
        this.mask = mask
        this.label = type_label

        if (
          this.authPaymentOnCheckout?.isCaptured === false &&
          this.authPaymentOnCheckout?.isVoided === false &&
          DateTime.fromISO(this.authPaymentOnCheckout?.expiresOn) >
            DateTime.local()
        ) {
          this.preAuthorizedAmount = this.authPaymentOnCheckout?.amount
          this.authorizedOn = this.authPaymentOnCheckout?.createdOn
        }
      }
    },
    async submitWithFee(params) {
      this.cancellationPenaltyAmount = params?.cancellationPenaltyAmount
      this.paymentProfileId = params?.paymentProfileId
      this.sendPaymentEmail = params?.sendPaymentEmail
      this.takeCancellationPenalty = true
      await this.submit()
    },
    async submit() {
      this.loading = true

      const newComment = {
        comments: this.clientInitiatedCancellation
          ? this.cancellationComment
          : 'Finalizing cancellation and closing ticket.',
        createdAt: new Date().toISOString(),
        createdById: this.currentUser.userId,
      }

      await ticket
        .partialUpdate({
          id: this.ticketId,
          payload: {
            ticketStatusTypeId: 3,
            cancelReservation: true,
            subTickets: [newComment],
            takeCancellationPenalty: this.takeCancellationPenalty,
            cancellationPenaltyAmount: this.cancellationPenaltyAmount,
            paymentProfileId: this.paymentProfileId,
            sendPaymentEmail: this.sendPaymentEmail,
          },
        })
        .catch((error) => {
          this.loading = false
          this.displayWarning = true
          this.warningMessage = error

          return false
        })
      this.$store.dispatch(
        'app/showAlert',
        { message: 'Ticket updated.' },
        { root: true }
      )
      EventBus.$emit('refresh-detail')
      EventBus.$emit('refresh-tickets')
      EventBus.$emit('refresh-reservation-detail')
      EventBus.$emit('refresh-reservation-payments')
      this.loading = false
      this.close()
    },
    scrollToBottom() {
      const sidebarElement = this.$refs.sidebar
      if (sidebarElement) {
        sidebarElement.scrollTo({
          top: sidebarElement.scrollHeight,
          behavior: 'smooth',
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.cancellation-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px 40px 0 40px;
  }
  overflow-y: auto;
}

.section-divider {
  border: 0;
  border-top: 1px solid $gray-light;
  margin: 20px 0;
}

.action-btn {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  bottom: 0;
  width: 500px !important;
  height: 71px !important;
  padding: 24px 0;
  width: inherit;
  border-radius: 0;
  background: $primary;
  z-index: 3;
}

::v-deep .accent--text {
  color: $blue !important;
}

.auth-payment-notice {
  display: flex;
}
</style>
