import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AuthPaymentResult } from '@/models/dto/AuthPayment'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

export const state = {}

export default {
  getCancellationPaymentInfo(quoteId: number): Promise<AxiosResponse<AuthPaymentResult>> {
    const url = `https://${baseUrl()}/authPayment/${quoteId}`
    return httpService.get(url)
  },
}
